import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RecetaIndex } from './services/receta/receta';
import { GlosarioIndex, HistoriaIndex, PlatoIndex, HomeIndex } from './services/index.services';
import { HttpModule } from '@angular/http';
import { HistoriaPage } from './pages/historia/historia.page';
import { PipesModule } from './pipe/pipes.module';
import { HistoriaPageModule } from './pages/historia/historia.module';
import { ComponentsModule } from './components/components.module';
import { ImagenPipe } from './pipe/imagen/imagen';
import {HttpClientModule} from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { GlosarioPageModule } from './pages/glosario/glosario.module';
import { PlatosPageModule } from './pages/platos/platos.module';
import { RecetasPageModule } from './pages/recetas/recetas.module';
import { HomePageModule } from './pages/home/home.module';
import { PlaceStaticPageModule } from './pages/place-static/place-static.module';
import { SearchPageModule } from './pages/search/search.module';
import { PlacesPageModule } from './pages/places/places.module';


@NgModule({
  declarations: [
    AppComponent,    
  
    ],
  entryComponents: [

  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(), 
    AppRoutingModule,   
    HttpModule , 
    ComponentsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
   //  GlosarioPageModule,
    // PlatosPageModule,
   //  RecetasPageModule,
    //// GlosarioPageModule,
    // HistoriaPageModule,
    // HomePageModule,
    // PlaceStaticPageModule,
    // SearchPageModule,
     //PlacesPageModule,
    PipesModule
    ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    RecetaIndex,
    GlosarioIndex,
    HistoriaIndex,
    PlatoIndex,
    HomeIndex,
    SocialSharing
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
