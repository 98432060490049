import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen/imagen';
import { ImagenHisPipe } from './imagen/imghis';
import { ImagenChifaPipe } from './imagen/imgchifa';
import { ImagenPlaPipe } from './imagen/imgpla';
import { ImaRecePipe } from './imagen/imgreceta';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
	declarations: [ImagenPipe,ImagenHisPipe,ImagenChifaPipe,ImagenPlaPipe,ImaRecePipe],
	exports: [ImagenPipe,ImagenHisPipe,ImagenChifaPipe,ImagenPlaPipe,ImaRecePipe],
	imports: [   CommonModule,
		IonicModule	]
})
export class PipesModule {}
