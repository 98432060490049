import { Pipe, PipeTransform } from '@angular/core';
import { URL_IMG } from '../../config/url.servicios';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'imgreceta'
})
export class ImaRecePipe implements PipeTransform {
  
   constructor(private domSanitazer: DomSanitizer){}

  transform(codigo: string) {
    const dommg = "background-image:url('"+ URL_IMG+"assets/img_receta/"+codigo+"')"
    return this.domSanitazer.bypassSecurityTrustStyle(dommg);
  }
}
